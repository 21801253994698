var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('top-nav'),_c('div',{staticClass:"home"},[_c('div',{staticClass:"homepage"},[_vm._m(0),_c('div',{staticClass:"part2RecoginizedIcons"},[_c('div',{staticClass:"part2-topcontent"},[_c('trusted')],1),_c('div',{staticClass:"part2-midcontent"},[_c('featuredIn')],1),_c('div',{staticClass:"part2-bottomcontent"},[_c('recognised')],1)]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"part5Comments"},[_c('other-product')],1)])]),_c('foot-nav')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"part1BookDemo"},[_c('div',{staticClass:"leftContent"},[_c('div',{staticClass:"part1title"},[_vm._v(" Arcanite Connect ")]),_c('div',{staticClass:"part1content"},[_vm._v(" Arcanite's mobile-friendly admin dashboard was designed for business managers on the go. Stay in control of your company's workflows, teams, projects and sales from any location with Arcanite Connect. ")]),_c('a',{attrs:{"onclick":"Calendly.showPopupWidget('https://meetings.hubspot.com/william463?embed=true');\n      return false;"}},[_c('button',{staticClass:"part1Button"},[_c('div',{staticClass:"bottomText"},[_vm._v("Book A Demo")]),_c('img',{staticClass:"p12",attrs:{"src":require("./../../assets/imgs/pc/homepage/p12Vector.png")}})])])]),_c('div',{staticClass:"rightContent"},[_c('img',{staticClass:"p1",attrs:{"src":require("./../../assets/imgs/pc/arcaniteConnect/p1.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"part3PicsText",staticStyle:{"height":"700px"}},[_c('img',{staticClass:"background",attrs:{"src":require("./../../assets/imgs/pc/arcanite/p2.png")}}),_c('div',{staticClass:"leftContent"},[_c('img',{staticClass:"pic2",attrs:{"src":require("./../../assets/imgs/pc/arcaniteConnect/p2.png")}})]),_c('div',{staticClass:"rightContent"},[_c('div',{staticClass:"p1"},[_c('img',{staticClass:"pic",attrs:{"src":require("./../../assets/imgs/pc/arcaniteConnect/minicons1.png")}}),_c('p',[_vm._v("View agent and project statistics")]),_c('div',{staticClass:"content"},[_vm._v(" Track your agents’ activity on the Arcanite platform and which projects are performing best. ")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"fullyLine"},[_c('div',{staticClass:"dog"}),_c('div',{staticClass:"text"},[_vm._v("Identify the amount of interest each project is receiving")]),_c('br')]),_c('div',{staticClass:"dog"}),_c('div',{staticClass:"text"},[_vm._v("Track agent logins and activity")]),_c('br'),_c('div',{staticClass:"dog"}),_c('div',{staticClass:"text"},[_vm._v("View expressions of interest, new contracts and sales exchanged")]),_c('br')])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"part3PicsText",staticStyle:{"height":"700px"}},[_c('div',{staticClass:"rightContent"},[_c('div',{staticClass:"p1"},[_c('img',{staticClass:"pic",attrs:{"src":require("./../../assets/imgs/pc/arcaniteConnect/minicons2.png")}}),_c('p',[_vm._v("Approve requests in the app")]),_c('div',{staticClass:"content"},[_vm._v(" Be notified of agent requests directly through Arcanite Connect. Review requests and approve instantly. ")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"dog"}),_c('div',{staticClass:"text"},[_vm._v("Speed up processes by eliminating response wait time")]),_c('br'),_c('div',{staticClass:"dog"}),_c('div',{staticClass:"text"},[_vm._v("Approve reservations and cancellations")]),_c('br'),_c('div',{staticClass:"dog"}),_c('div',{staticClass:"text"},[_vm._v("Update sales and confirm deposits")]),_c('br')])])]),_c('div',{staticClass:"leftContent"},[_c('img',{staticClass:"pic3",attrs:{"src":require("./../../assets/imgs/pc/arcaniteConnect/p3.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"part3PicsText"},[_c('div',{staticClass:"leftContent"},[_c('img',{staticClass:"pic4",attrs:{"src":require("./../../assets/imgs/pc/arcaniteConnect/p4.png")}})]),_c('div',{staticClass:"rightContent"},[_c('div',{staticClass:"p1",staticStyle:{"top":"20%"}},[_c('img',{staticClass:"pic",attrs:{"src":require("./../../assets/imgs/pc/arcaniteConnect/minicons3.png")}}),_c('p',[_vm._v("Keep your team updated")]),_c('div',{staticClass:"content"},[_vm._v(" When changes to a project occur, use Arcanite Connect to instantly update new details on your agents Arcanite app. ")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"dog"}),_c('div',{staticClass:"text"},[_vm._v("Adjust property prices and availability")]),_c('br'),_c('div',{staticClass:"dog"}),_c('div',{staticClass:"text"},[_vm._v("Amend sales statuses as changes occur")]),_c('br'),_c('div',{staticClass:"dog"}),_c('div',{staticClass:"text"},[_vm._v("Keep Arcanite app’s information up to date for your agents")]),_c('br')])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"part3PicsText",staticStyle:{"height":"700px"}},[_c('div',{staticClass:"rightContent"},[_c('div',{staticClass:"p1",staticStyle:{"top":"20%"}},[_c('img',{staticClass:"pic",attrs:{"src":require("./../../assets/imgs/pc/arcaniteConnect/minicons4.png")}}),_c('p',[_vm._v("Communicate with your team")]),_c('div',{staticClass:"content"},[_vm._v(" Send instant notifications to your entire team or selected members from Arcanite Connect to their Arcanite app. ")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"dog"}),_c('div',{staticClass:"text"},[_vm._v("Include necessary attachments")]),_c('br'),_c('div',{staticClass:"dog"}),_c('div',{staticClass:"text"},[_vm._v("Schedule when notifications are to be sent")]),_c('br'),_c('div',{staticClass:"dog"}),_c('div',{staticClass:"text"},[_vm._v("Select to notify the entire team or selected members only")]),_c('br')])])]),_c('div',{staticClass:"leftContent"},[_c('img',{staticClass:"pic5",attrs:{"src":require("./../../assets/imgs/pc/arcaniteConnect/p5.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"part4Feature"},[_c('img',{staticClass:"pic4",attrs:{"src":require("./../../assets/imgs/pc/arcaniteConnect/p6.png")}}),_c('h2',[_vm._v(" Join the thousands of real estate professionals using Arcanite to streamline their teams everyday project marketing processes. ")]),_c('a',{attrs:{"onclick":"Calendly.showPopupWidget('https://meetings.hubspot.com/william463?embed=true');\n    return false;"}},[_c('button',{staticClass:"part4Button"},[_c('div',{staticClass:"bottomText"},[_vm._v("Book A Demo")]),_c('img',{staticClass:"p12",attrs:{"src":require("./../../assets/imgs/pc/homepage/p12Vector.png")}})])])])
}]

export { render, staticRenderFns }