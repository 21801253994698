<template>
  <div>
    <top-nav></top-nav>
    <div class="home">
    <div class="homepage">
      <div class="part1BookDemo">
        <div class="leftContent">
          <div class="part1title">
            Arcanite Connect
          </div>
          <div class="part1content">
            Arcanite's mobile-friendly admin dashboard was designed for business managers on the go. Stay in control of your company's workflows, teams, projects and sales from any location with Arcanite Connect. 
            <!-- Arcanite Connect is the best way to stay in command of your company’s workflows from any location. The mobile-friendly admin dashboard was made for business managers on the go. Manage your Arcanite platform and access everything you need to remain in control of your teams’ projects and sales. -->
          </div>
          <a onclick="Calendly.showPopupWidget('https://meetings.hubspot.com/william463?embed=true');
        return false;">
          <button class="part1Button"><div class="bottomText">Book A Demo</div><img src="./../../assets/imgs/pc/homepage/p12Vector.png" class="p12"></button>
          </a>
        <!-- <recognised></recognised>
        <trusted></trusted> -->
          </div>
            <div class="rightContent">
              <img src="./../../assets/imgs/pc/arcaniteConnect/p1.png" class="p1"/>
            </div>
      </div>

      <div class="part2RecoginizedIcons">
        <div class="part2-topcontent">
          <trusted></trusted>
        </div>
        <div class="part2-midcontent">
          <featuredIn></featuredIn>
        </div>
        <div class="part2-bottomcontent">
            <recognised></recognised>
        </div>
      </div>

      <div class="part3PicsText" style="height:700px">
        <img src="./../../assets/imgs/pc/arcanite/p2.png" class="background" />
        <div class="leftContent">
          <img src="./../../assets/imgs/pc/arcaniteConnect/p2.png" class="pic2" />
        </div>
        <div class="rightContent" >
          <div class="p1">
            <img src="./../../assets/imgs/pc/arcaniteConnect/minicons1.png" class="pic" />
            <p>View agent and project statistics</p>
            <div class="content">
                Track your agents’ activity on the Arcanite platform and which projects are performing best.
            </div>
            <div class="line"></div>
            <div class="content">
              <div class="fullyLine">
              <div class="dog"></div><div class="text">Identify the amount of interest each project is receiving</div><br>
              </div>
              <div class="dog"></div><div class="text">Track agent logins and activity</div><br>
              <div class="dog"></div><div class="text">View expressions of interest, new contracts and sales exchanged</div><br>
            </div>
          </div>
        </div>
      </div>
      <div class="part3PicsText" style="height:700px">
        <div class="rightContent">
          <div class="p1">
            <img src="./../../assets/imgs/pc/arcaniteConnect/minicons2.png" class="pic" />
            <p>Approve requests in the app</p>
            <div class="content">
                Be notified of agent requests directly through Arcanite Connect. Review requests and approve instantly. 
            </div>
            <div class="line"></div>
            <div class="content">
              <div class="dog"></div><div class="text">Speed up processes by eliminating response wait time</div><br>
              <div class="dog"></div><div class="text">Approve reservations and cancellations</div><br>
              <div class="dog"></div><div class="text">Update sales and confirm deposits</div><br>
            </div>
          </div>
        </div>
        <div class="leftContent">
          <img src="./../../assets/imgs/pc/arcaniteConnect/p3.png" class="pic3" />
        </div>
      </div>
      <div class="part3PicsText" >
        <div class="leftContent">
          <img src="./../../assets/imgs/pc/arcaniteConnect/p4.png" class="pic4" />
        </div>
        <div class="rightContent">
          <div class="p1" style="top:20%">
            <img src="./../../assets/imgs/pc/arcaniteConnect/minicons3.png" class="pic" />
            <p>Keep your team updated</p>
            <div class="content">
            When changes to a project occur, use Arcanite Connect to instantly update new details on your agents Arcanite app.
            </div>
            <div class="line"></div>
            <div class="content">
              <div class="dog"></div><div class="text">Adjust property prices and availability</div><br>
              <div class="dog"></div><div class="text">Amend sales statuses as changes occur</div><br>
              <div class="dog"></div><div class="text">Keep Arcanite app’s information up to date for your agents</div><br>
            </div>
          </div>
        </div>
      </div>
      <div class="part3PicsText" style="height:700px">
        <div class="rightContent">
          <div class="p1" style="top:20%">
            <img src="./../../assets/imgs/pc/arcaniteConnect/minicons4.png" class="pic" />
            <p>Communicate with your team</p>
            <div class="content">
            Send instant notifications to your entire team or selected members from Arcanite Connect to their Arcanite app.
            </div>
            <div class="line"></div>
            <div class="content">
              <div class="dog"></div><div class="text">Include necessary attachments</div><br>
              <div class="dog"></div><div class="text">Schedule when notifications are to be sent</div><br>
              <div class="dog"></div><div class="text">Select to notify the entire team or selected members only</div><br>
            </div>
          </div>
        </div>
        <div class="leftContent">
          <img src="./../../assets/imgs/pc/arcaniteConnect/p5.png" class="pic5" />
        </div>
      </div>
      <div class="part4Feature">
        <img src="./../../assets/imgs/pc/arcaniteConnect/p6.png" class="pic4" />
        <h2>
            Join the thousands of real estate professionals using Arcanite to streamline their teams everyday project marketing processes.
        </h2>
        <a onclick="Calendly.showPopupWidget('https://meetings.hubspot.com/william463?embed=true');
      return false;">
          <button class="part4Button"><div class="bottomText">Book A Demo</div><img src="./../../assets/imgs/pc/homepage/p12Vector.png" class="p12"></button>
        </a>
      </div>
      <div class="part5Comments">
        <other-product></other-product>
      </div>
    </div>
    </div>
    <foot-nav></foot-nav>
  </div>
</template>
<script>
export default {
  data() {
      return{
      }
  },
  created() {
  },
  mounted() {
  },
  watch: {
  },
  methods: {
  },
  components: {
    "top-nav": (resolve) => require(["./TopNav.vue"], resolve),
    "foot-nav": (resolve) => require(["./FooterNav.vue"], resolve),
    "other-product": (resolve) => require(["./OtherProduct.vue"], resolve),
    "trusted":(resolve) => require(["./Trusted.vue"], resolve),
    "recognised":(resolve) => require(["./Recognised.vue"], resolve),
    "featuredIn":(resolve) => require(["./FeaturedIn.vue"], resolve),
    "testimonialCard":(resolve) => require(["./TestimonialCard.vue"], resolve),
  },
};
</script>
<style lang="scss" scoped>
.home{
  width: 100vw;
  display: inline-block;
  text-align: center;
  overflow-x:hidden;
  font-family: Poppins;
  min-width: 1210px;
}

.homepage{
  margin: auto;
  width:1210px;
  display: block;
  position: relative;
}
.part1BookDemo{
  height: 100%;
  width:1210px;
  margin: 0 0 3px;
  padding: 0 0 13px;
  margin-bottom: 200px;
  // background-color: white;
  z-index: 0;
  position: relative;
  justify-content: space-around;
  .p1{
    width: 532px;
    height: 670px;
    position:absolute;
    top:100px;
    right:0px;
    z-index: 1;
  }
  .p11{
    width: 237px;
    height: 421px;
    position:absolute;
    bottom:0;
    right:0;
    z-index: 2;
  }
  .p12{
    width: 22px;
    height: 22px;
    flex-grow: 0;
  }
  .dogs{
    width: 65px;
    height: 50px;
  }
  .p13{
    position:absolute;
    bottom:110px;
    left:-30px;
    z-index: 0;
  }
  .p14{
    position:absolute;
    top:-55px;
    right:40px;
    z-index: 0;
  }
  .leftContent{
    width: 520px;
    // height: 466px;
    padding-top: 188px;
    padding-left: 50px;
  }
  .rightContent{
    width:646.8px;
    height:100%;
    position:absolute;
    bottom:0;
    right:50px;
    z-index: -1;
  }
  .part1title{
    flex-grow: 0;
    font-family: Poppins-Bold;
    font-size: 56px;
    // font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    text-align: left;
    color: #062440;
    margin-bottom: 30px;
  }

  .part1content{
    width: 500px;
    height: 180px;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #314455;
  }

  .part1Button{
    margin-top: 50px;
    margin-bottom: 60px;
    width: 175px;
    height: 42px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px 16px;
    border-radius: 5px;
    background-color: #1890ff;
  }
  .part1Button:hover{
    background-color: #096dd9;
  }
    .part1Button:active{
    background-color: #0757ae;
  }
  .bottomText{
    width: 111px;
    height: 22px;
    flex-grow: 0;
    font-family: Poppins-Bold;
    font-size: 16px;
    // font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    white-space: nowrap;
  }
}

.part2RecoginizedIcons{
  margin: 0 auto;
  width: 1210px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items:center;
  // padding-left: 20px;
  gap: 40px;
  .title{
    margin: 10px;
    width: 84px;
    height: 18px;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #67788c;
  }
}

.part3PicsText{
  margin-top: 101px;
  display: flex;
  position: relative;
  gap: 25px;
  .background{
    width: 2340px;
    height: 1122px;
    position: absolute;
    top:-250px;
    left:-565px;
    z-index: -99;
  }
  .leftContent{
    position:relative;
    display: inline-block;
    width: 680px;
    height: 597px;
    .p21{
      width: 744px;
      height: 724px;
      position: absolute;
      top: 0;
      left:0;
    }
  }
  .rightContent{
    position:relative;
    display: inline-block;
    width: 454px;
    // margin-left: 25px;
    height: 597px;
    }
    .p1{
      width: 500px;
      margin: 10px;
      position:absolute;
      top: 20%;
      text-align: left;
      .pic1{
        width: 80px;
        height: 80px;
      }
      p{
        margin-left: 10px;
        position:relative;
        font-family: Poppins-Bold;
        font-size: 36px;
        // font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: normal;
        text-align: left;
        color: #062440;
      }
      .content{
        margin-left: 10px;
        width: 454px;
        position:relative;
        flex-grow: 0;
        font-family: Poppins;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #314455;
        margin-top:10px;
      }
      .line{
        margin: 20px 0 20px 10px;
        width: 463px;
        height: 1px;
        background-color: #e6ebf2;
      }
      .dog{
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #1890ff;
        display: inline-block;
      }
      .text{
        margin: 10px 10px 10px 10px;
        display: inline-block;
        flex-grow: 0;
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: #314455;
        // word-break: break-all;
      }
      .content{
        width:490px ;
        text-align: left;
      }
      // .fullyLine{
      //   width:493px ;
      //   // word-break: ;
      // }
  }
}
.pic2{
  width: 738px;
  height: 721px;
  position: absolute;
  left: 0;
  top:100px;
}
.pic3{
  width: 704px;
  height: 748px;
  position: absolute;
  right: -20px;
}
.pic4{
  width: 571px;
  height: 694px;
  position: absolute;
  left: 50px;
}
.pic5{
    width: 590px;
    height: 694px;
    position: absolute;
    left: 200px;
}

.part4Feature{
  margin:auto;
  // background-image: url("./../../assets/imgs/pc/homepage/p4.png");
  width:1210px;
  height:353px;
  // display: flex;
  position: relative;
  // overflow: hidden;
    // text-align: center;
    // display: inline-block;
    z-index: 0;
  .pic4{
    z-index: -99;
    width: 2560px;
    height: 354px;
    position: absolute;
    left: -675px;
  }
    h2{
      margin: auto;
      padding-top: 57px;
      width: 852px;
      height: 168px;
      flex-grow: 0;
      font-family: Poppins-Bold;
      font-size: 36px;
      // font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }
    .part4Button{
       width: 175px;
       height: 42px;
       flex-grow: 0;
       display: flex;
       flex-direction: row;
       justify-content: center;
       align-items: center;
       gap: 10px;
       padding: 10px 16px;
       border-radius: 5px;
       background-color: #062440;
       margin-left: 179px;
       margin-top: 30px;
       margin-bottom: 57px;
       // position: absolute;
       // left: 179px;
       // top:275px;
       z-index: 60;
    }
    .part4Button:hover{
      background-color: #041729;
    }
    part4Button:active{
      background-color: #020d17;
    }
    .bottomText{
        flex-grow: 0;
        font-family: Poppins-Bold;
        font-size: 16px;
        // font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        z-index: 1;
        white-space: nowrap;
    }
    .p12{
        width: 22px;
        height: 22px;
        flex-grow: 0;
        object-fit: contain;
    }
}
.part5Comments{
  width: 1210px;
  height: 883px;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.pic{
    width: 91px;
    height: 91px;
}

</style>